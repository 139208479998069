<template>
  <v-expansion-panel>
    <v-expansion-panel-header style="text-transform:uppercase" class="text-start font-weight-bold primary--text no-horizontal-align">Dados de operação</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card flat>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="6" md="4">
              <v-card style="display: flex; justify-content: center; align-items:center" color="primary" min-height="100" class="pa-2">
                <div>
                    <span style="font-size: 12px" class="white--text text-center">Prazo/carência</span>
                    <div  style="font-size: 16px" class="white--text text-center">{{ negocio.numero_parcelas }} meses</div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6" md="4">
                <v-card style="display: flex; justify-content: center; align-items:center" color="primary" min-height="100" class="pa-2">
                    <div>
                        <span style="display: flex; justify-content: center; align-items:center; font-size: 12px" class="white--text text-center">Taxa de rentabilidade</span>
                        <div class="white--text text-center">{{ formatPercentage(negocio.taxa) }}</div>
                    </div>
              </v-card>
            </v-col>
            <v-col cols="6" md="4">
                <v-card style="display: flex; justify-content: center; align-items:center" color="primary" min-height="100" class="pa-2">
                    <div>
                        <span style="font-size: 12px" class="white--text text-center">Ticket Mínimo</span>
                        <div style="font-size: 16px" class="white--text text-center">{{ formatCurrency(negocio.ticket_minimo) }}</div>
                    </div>
              </v-card>
            </v-col>
            <v-col cols="6" md="4">
                <v-card style="display: flex; justify-content: center; align-items:center" color="primary" min-height="100" class="pa-2">
                    <div>
                        <span style="display: flex; justify-content: center; align-items:center; font-size: 12px" class="white--text text-center">Mínimo de captação da oferta</span>
                        <div style="font-size: 16px" class="white--text text-center">{{ formatCurrency(negocio.valor_minimo) }}</div>
                    </div>
              </v-card>
            </v-col>
            <v-col cols="6" md="4">
                <v-card style="display: flex; justify-content: center; align-items:center" color="primary" min-height="100" class="pa-2">
                    <div>
                        <span  style="font-size: 12px" class="white--text text-center">Valor solicitado</span>
                        <div style="font-size: 16px" class="white--text text-center">{{ formatCurrency(negocio.valor) }}</div>
                    </div>
              </v-card>
            </v-col>
            <v-col cols="6" md="4">
                <v-card style="display: flex; justify-content: center; align-items:center" color="primary" min-height="100" class="pa-2">
                    <div>
                        <span style="display: flex; justify-content: center; align-items:center; font-size: 12px" class="white--text text-center">Data limite para reserva</span>
                        <div style="font-size: 16px" class="white--text text-center">{{ formatData(negocio.data_limite) }}</div>
                    </div>
              </v-card>
            </v-col>
            <v-col cols="12">
                <v-card style="display: flex; justify-content: center; align-items:center" color="primary" min-height="100" class="pa-2">
                    <div>
                        <span style="font-size: 12px" class="white--text text-center">Data prevista para transferência dos recursos</span>
                        <div style="font-size: 16px" class="white--text text-center">{{ formatData(negocio.prev_desembolso) }}</div>
                    </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- <div class="mt-4">
            <span>*O período de reserva de investimento é o momento onde os investidores efetuam a solicitação do valor que querem investir. Ao chegar em 100% do valor total solicitado pela empresa, o período de reserva de investimento é encerrado imediatamente.</span>
          </div> -->
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>

export default {
  props: {
    negocio: Object,
    formatCurrency: Function,
    formatPercentage: Function,
    formatData: Function
  },
};
</script>
