<template>
  <layout :click-busi="false">
    <template v-slot:content>
      <v-container style="border: 0px" class="px-5 mt-5 custom-container" fluid>
        <v-dialog v-model="dialog">
          <SimulationInvest @close="close" :negocio="negocio" :titularUser="titularUser"/>
        </v-dialog>
        <v-row class="w-100" max-width="100%">
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <v-card outlined style="border-radius: 2%;border: 2px solid rgba(20, 170, 149, 0.5)" class="pa-3">
              <div class="pb-2">
                <v-img height="120" class="align-end text-white" :src="getPhoto(negocio?.logo)"></v-img>
                <v-card-text>
                  <ProgressBar :color="getProgressBarColor(negocio.neg_area_id)" :value="calcPercentInvestiment(negocio)" />

                  <div class="text-center mt-2">
                    <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold" style="font-size: 10px !important;">Valor total do empréstimo</span>
                  </div>
                  <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded text-center">
                    <span class="primary--text font-weight-light font-aleo " style="font-size: 16px; padding-top: 0;">
                      {{ formatCurrency(Math.floor(parseFloat(negocio.valor_a_ser_captado) / 1000) * 1000) }}
                    </span>
                  </v-col>
                  
                  <v-row class="pb-0 px-1 pt-3">
                    <v-col cols="6" class="py-0 px-2">
                      <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2" style="font-size: 9px !important;">
                        Taxa de retorno
                        <InfoTooltip content="A Taxa de Juros acima é igual ao seu Retorno Mensal Bruto (antes do desconto de IR, custos de transferência e potencial inadimplência)." />
                      </div>
                    </v-col>
                    <v-col cols="6" class="py-0 px-2">
                      <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2" style="font-size: 9px !important;">
                        Prazo de operação
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0">
                    <v-col class="mr-1 ml-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                      <span class="primary--text font-weight-light font-aleo" style="font-size: 16px; padding-top: 0;">
                        {{ formatPercentage(parseFloat(negocio.taxa)) }} <br>
                        <span class="texto2 primary--text font-weight-medium" style="padding: 0 !important;">NOMINAL MENSAL</span>
                      </span>
                    </v-col>
                    <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                      <span class="primary--text align-center font-weight-light font-aleo" style="font-size: 16px; padding-top: 0;">
                        {{ negocio.prazo }} MESES
                      </span>
                    </v-col>
                  </v-row>

                  <div class="text-center mt-4">
                    <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold" style="font-size: 10px !important;">Investimento Mínimo</span>
                  </div>
                  <div class="grey lighten-3 rounded align-center text-center" style="padding-top: 0">
                    <span class="primary--text font-weight-light font-aleo" style="font-size: 16px; padding-top: 0;">
                      {{ formatCurrency(parseFloat(negocio.ticket_minimo)) }}
                    </span>
                  </div>

                  <SimulateButton @click="dialog = true" :disabled="cantInvest" />

                  <div class="mt-3 px-0 bx-0">
                    <span class="porcentagem primary--text" style="font-size: 10px !important;">IMPACTO SOCIOAMBIENTAL DECLARADO:</span>
                    <p class="porcentagem primary--text" style="font-size: 10px !important;">
                      {{ negocio.impacto_socioambiental?.length > 80 ? negocio.impacto_socioambiental.substring(0, 80) + '...' : negocio.impacto_socioambiental }}
                    </p>
                  </div>
                  
                  <ImageGrid :negocio="negocio" />
                </v-card-text>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="9" class="ml-0 bl-0">
            <v-expansion-panels flat accordion>
              <CompanyDataPanel :negocio="negocio" :formatData="formatData"/>
              <PositiveImpactPanel :negocio="negocio" />
              <FinancialDataPanel :negocio="negocio" :formatCurrency="formatCurrency"/>
              <OperationDataPanel :negocio="negocio" :formatCurrency="formatCurrency" :formatPercentage="formatPercentage" :formatData="formatData"/>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <fullscreen-image-modal :dialog="isImageModalOpen" :imageSrc="selectedImage" @update:dialog="isImageModalOpen = $event" />
      </v-container>
    </template>
  </layout>
</template>

<script>
import { getPhoto, numberToReal, numberToPercentage } from "@/js/belatUtils";
import layout from "@/layouts/internalLayoutMobile.vue";
import SimulationInvest from "./Modal/SimulationInvestMobile.vue";
import FullscreenImageModal from '../desktop/Components/FullscreenImageModal.vue';

import ProgressBar from "../desktop/Components/ProgressBar.vue";
import SimulateButton from "./Components/SimulateButton.vue";
import InfoTooltip from "./Components/InfoTooltip.vue";
import ImageGrid from "./Components/ImageGrid.vue";
import CompanyDataPanel from "./Components/CompanyDataPanel.vue";
import PositiveImpactPanel from "./Components/PositiveImpactPanel.vue";
import FinancialDataPanel from "./Components/FinancialDataPanel.vue";
import OperationDataPanel from "./Components/OperationDataPanel.vue";

import businessMixin from "@/mixins/businessMixin";
import investorMixin from "@/mixins/investorMixin";
import formatMixin from "@/mixins/formatMixin";

export default {
  metaInfo() {
    return {
      title: 'BELat - Negócio'
    }
  },
  components: {
    SimulationInvest,
    layout,
    FullscreenImageModal,
    ProgressBar,
    SimulateButton,
    InfoTooltip,
    ImageGrid,
    CompanyDataPanel,
    PositiveImpactPanel,
    FinancialDataPanel,
    OperationDataPanel,
  },
  mixins: [businessMixin, investorMixin, formatMixin],
  data() {
    return {
      isImageModalOpen: false,
      selectedImage: '',
      dialog: false,
      negocio: {},
      token: localStorage.getItem("token"),
      titularUser: {},
      desserts: [
          { name: 'Valor Total', category: '' },
          { name: 'Número de parcelas', category: '' },
          { name: 'Taxa de rentabilidade', category: '' },
          { name: 'Aporte mínimo', category: '' },
          { name: 'Garantias', category: '' },
          { name: 'Mínimo de captação da oportunidade', category: '' },
          { name: 'Data limite para reserva', category: '' },
          { name: 'Data prevista para transferência dos recursos', category: '' },
      ],
    };
  },
  methods: {
    openImageModal(imageSrc) {
      this.selectedImage = imageSrc;
      this.isImageModalOpen = true;
    },
    close() {
      this.dialog = false;
    },
  },
  computed: {
        getPhoto() {
            return getPhoto;
        },
        numberToReal() {
            return numberToReal;
        },
        numberToPercentage() {
            return numberToPercentage;
        },
  },
  mounted() {
    const businessId = this.$route.params.businessId;
    this.getBusinessDetail(businessId);
    this.getInvestidor();
    window.scrollTo(0, 0);
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');
.no-horizontal-align{
  justify-content: initial !important;
}
.font-aleo {
  font-family: 'Aleo', sans-serif !important;
}
</style>
