<template>
    <v-card @click="onDetailClick" style="border: 1px solid rgba(20, 170, 149, 0.5); border-radius: 3%;"
        class="mx-auto pa-1" max-width="350" outlined>
        <v-img height="250" contain class="align-end text-white"
            v-bind:src="getPhoto(business.fotos)" :gradient="getGradient(business.neg_area_id)">
            <v-card-title style="padding: 5px 16px">
                <span class="white--text font-weight-bold">
                    {{ getNegAreaDescription(business.neg_area_id) }}
                </span>
            </v-card-title>
        </v-img>
        <v-card-title style="padding: 5px 16px">{{ business.nome }}</v-card-title>
        <v-card-text>
            <v-progress-linear background-color="grey lighten-3"
                :color="getProgressBarColor(business.neg_area_id)"
                :value="calcPercentInvestiment(business)" height="15" rounded
                :style="{ fontSize: '10px', borderRadius: '8px', border: '1px solid #ccc' }">
                <template v-slot:default="{ value }">
                    <span :class="{ 'white--text': value > 50 }">
                        {{ Math.ceil(value) }}%
                    </span>
                </template>
            </v-progress-linear>
            <div class="text-center mt-2">
                <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
                    style="font-size: 10px !important;">Valor total do empréstimo</span>
            </div>
            <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded text-center">
                <span class="font-aleo primary--text  font-weight-light"
                    style="font-size: 16px; padding-top: 0;">
                    {{ numberToReal(Math.floor(parseFloat(business.valor_a_ser_captado) / 1000) *
                        1000) }}
                </span>
            </v-col>
            <v-row class="pb-0 px-1 pt-3">
                <v-col cols="6" class="py-0 px-2">
                    <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
                        style="font-size: 9px !important;">
                        Taxa de retorno
                    </div>
                </v-col>
                <v-col cols="6" class="py-0 px-2">
                    <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
                        style="font-size: 9px !important;">
                        Prazo de operação
                    </div>
                </v-col>
            </v-row>
            <v-row class="pa-0">
                <v-col
                    class="mr-1 ml-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                    <span class="font-aleo primary--text  font-weight-light"
                        style="font-size: 16px; padding-top: 0;">{{ numberToPercentage(parseFloat(business.taxa)) }} <br>
                        <span class="texto2 primary--text font-weight-medium"
                            style="padding: 0 !important;">NOMINAL MENSAL</span>
                    </span>
                </v-col>
                <v-col
                    class="ml-1 mr-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                    <span class="font-aleo primary--text align-center font-weight-light"
                        style="font-size: 16px; padding-top: 0;">{{ business.prazo }} MESES</span>
                </v-col>
            </v-row>
            <div class="text-center mt-4">
                <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
                    style="font-size: 10px !important;">Investimento Mínimo</span>
            </div>
            <div class="grey lighten-3 rounded align-center text-center" style="padding-top: 0">
                <span class="font-aleo primary--text font-weight-light"
                    style="font-size: 16px; padding-top: 0;">{{ numberToReal(parseFloat(business.ticket_minimo)) }}</span>
            </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center flex">
            <v-hover v-slot="{ hover }">
                <v-btn @click="onDetailClick" style="width: 100%"
                    :class="{ 'on-hover': hover }" class="rounded-xl px-16 transition-fast-in-fast-out"
                    :color="hover ? 'secondary' : 'primary'">
                    faça uma simulação
                </v-btn>
            </v-hover>
        </v-card-actions>
    </v-card>
</template>

<script>
import { getPhoto, numberToReal, numberToPercentage } from "@/js/belatUtils";

export default {
    props: {
        business: Object,
        onDetailClick: Function,
        getGradient: Function,
        getNegAreaDescription: Function,
        getProgressBarColor: Function,
        calcPercentInvestiment: Function,
    },
    computed: {
        getPhoto() {
            return getPhoto;
        },
        numberToReal() {
            return numberToReal;
        },
        numberToPercentage() {
            return numberToPercentage;
        },
    },
};
</script>
